//components
import React, { Component } from 'react'
//library
import moment from "moment"
import InputText from "../../components/common/inputs/InputText"
//Services
import { DownloadInputFile } from "../../services/axiosServices/ApiEndPoints"

export class DataHistory extends Component {
    render(props) {
        return (
            <>
            <div>
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                    {this.props.importDetails.importType === "Product" ?
                        <div className="mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Product History</h1>
                        </div> : null}
                    {this.props.importDetails.importType === "Stock" ?
                        <div className="mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Stock History</h1>
                        </div> : null}
                    {this.props.importDetails.importType === "MolProduct" ?
                        <div className="mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Mol-Product History</h1>
                        </div> : null}
                    {this.props.importDetails.importType === "Price" ?
                        <div className="mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Price History</h1>
                        </div> : null}
                </div>
                <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">

                        <div
                            id="accordionOne"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body main-card-details-sec">
                                    <div>
                                        <div className="row">
                                            <div className="mb-3 col-md-6">
                                                <label for="Tittle" className="form-label-check1">Title :</label>
                                                <label className=" ml-2">  {this.props.importDetails.importTitle ? this.props.importDetails.importTitle : "NA"} </label>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="FileName" className="form-label-check1"> File Name : </label>
                                                <label className=" ml-2">  {this.props.importDetails.importFileName ? this.props.importDetails.importFileName : "NA"}  </label>
                                                <label className="ml-3">
                                                    <div className="download-container">
                                                        <i className="fa fa-download"></i>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="Status" className="form-label-check1">Status :</label>
                                                <label className=" ml-2"> {this.props.importDetails.importStatusType ? this.props.importDetails.importStatusType : "NA"}  </label>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="StartDate" className="form-label-check1">Start Date :</label>
                                                <label className=" ml-2">   {this.props.importDetails.importStartDate ? moment(this.props.importDetails.importStartDate).format("yyyy-MM-DD HH:mm:ss") : "NA"}   </label>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="RequestBy" className="form-label-check1">RequestBy :</label>
                                                <label className=" ml-2">
                                                    {this.props.importDetails.requestedBy == 1 ?
                                                        "Web"
                                                        :
                                                        "NA"
                                                    }
                                                </label>
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="StartDate" className="form-label-check1">Finish Date :</label>
                                                <label className=" ml-2">  {this.props.importDetails.finishDate ? moment(this.props.importDetails.finishDate).format("yyyy-MM-DD HH:mm:ss") : "NA"}  </label>
                                            </div>
                                            {this.props.importDetails.importType === "Product" || this.props.importDetails.importType === "Stock" || this.props.importDetails.importType === "Price" ?
                                                <div className="mb-3 col-md-6">
                                                    <label for="StartDate" className="form-label-check1">SupplierName :</label>
                                                    <label className=" ml-2">  {this.props.importDetails.supplierNames ? this.props.importDetails.supplierNames : "NA"}  </label>
                                                </div> :
                                                null
                                            }

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion mt-5" id="accordionExample">
                    <div className="card accordion-item active">
                        <header className="px-5 pb-0 py-4 border-b border-slate-100">
                            <h3 className="font-semibold text-slate-800">Process Report </h3>
                        </header>
                        <div
                            id="accordionOne"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body main-card-details-sec">
                                    <div>
                                        <div className="row">
                                            <div className="mb-3 col-md-6">
                                                <label for="TotalProduct" className="form-label-check1">Total Records :</label>
                                                <label className=" ml-2">  {this.props.importDetails.totalRecords ? this.props.importDetails.totalRecords : "NA"} </label>
                                                {this.props.importDetails.totalRecords ?
                                                    <label className="ml-3">
                                                        <div className="download-container">
                                                            <a href={`${DownloadInputFile + `?FileName=${this.props.importDetails.outputFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                        </div>
                                                    </label> : null}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="TotalProduct" className="form-label-check1">Error Records :</label>
                                                <label className=" ml-2">  {this.props.importDetails.noOfErrorInRecords ? this.props.importDetails.noOfErrorInRecords : "NA"} </label>
                                                {this.props.importDetails.noOfErrorInRecords ?
                                                    <label className="ml-3">
                                                        <div className="download-container">
                                                            <a href={`${DownloadInputFile + `?FileName=${this.props.importDetails.errorFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                        </div>
                                                    </label> : null}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="TotalProduct" className="form-label-check1"> New Records :</label>
                                                <label className=" ml-2">  {this.props.importDetails.noOfNewRecords ? this.props.importDetails.noOfNewRecords : "NA"} </label>
                                                {this.props.importDetails.noOfNewRecords ?
                                                    <label className="ml-3">
                                                        <div className="download-container">
                                                            <a href={`${DownloadInputFile + `?FileName=${this.props.importDetails.newRecordsFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                        </div>
                                                    </label> : null}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label for="TotalProduct" className="form-label-check1"> Already Exists :</label>
                                                <label className=" ml-2">  {this.props.importDetails.noOfAlreadyExitsRecords ? this.props.importDetails.noOfAlreadyExitsRecords : "NA"} </label>
                                                {this.props.importDetails.noOfAlreadyExitsRecords ?
                                                    <label className="ml-3">
                                                        <div className="download-container">
                                                            <a href={`${DownloadInputFile + `?FileName=${this.props.importDetails.alreadyExistsFileName}&ModuleType=DataImportingType`}`} target="_top"> <i className="fa fa-download"></i></a>
                                                        </div>
                                                    </label> : null}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </>


        )
    }

}
