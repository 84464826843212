//components
import React from 'react';

const PageLoader = () => {
  return (
    <div id="preloader" aria-busy="true" aria-label="Loading, please wait." role="progressbar">
      <div class="icon">
        RPA
      </div>
    </div>
  )
}

export default PageLoader
