//components
import React, { Component } from 'react'

export class Dashboard extends Component {
  render() {
    return (
      <div className="ms-4">Page Will Be Design Soon</div>
    )
  }
}

export default Dashboard