// component
import React, { } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
// library
import { Outlet } from 'react-router';

const Layout = (props) => {

  return (
    <div>
      <div className="layout-wrapper layout-content-navbar  ">
        <Sidebar componentRoutes={props.componentRoutes} />
        <div className="layout-container ">
          <div className="content-wrapper">
            <Header componentRoutes={props.componentRoutes} />
            <div className='container-xxl flex-grow-1 container-p-y  center-sec-main boxSize'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout;
