//components
import React, { useState, useMemo } from 'react';
//library
import Pagination from './PaginationCustom';
import PageLoader from "../common/pageLoader/PageLoader"

const defaultScopeAction = (element, value) => {

    return (value) ?
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}>Active</div>
        </td >
        :
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className={`inline-flex font-medium rounded-full text-center  bg-red-500 px-2.5 py-0.5 `}>In Active</div>
        </td >
}

const availabilityAction = (element, value) => {

    return (
        <>
        {(() => {
            switch (value) {
                case 1:
                    return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}><i className=" icon-sm fa fa-check-circle fw-bold mt-1 mr-1"></i> In Stock</div></td >
                case 2:
                    return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}><i className=" icon-sm fal fa-history fw-bold mt-1 mr-1"></i> Backordered</div></td >
                case 3:
                    return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}><i className="icon-sm fal fa-ban fw-bold mt-1 mr-1"></i> Suspended</div></td >
                case 4:
                    return <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"><div className={`inline-flex font-medium rounded-full text-center bg-emerald-500 px-2.5 py-0.5 `}><i className=" icon-sm fal fa-conveyor-belt-alt fw-bold mt-1 mr-1"></i> In Production</div></td >
                default:
                    return null
            }
        })()}
        </>
    )
}

const defaultScope = [
    {
        column: 'isActive',
        renderTableData: defaultScopeAction.bind(this)
    },
    {
        column: 'availabilityId',
        renderTableData: availabilityAction.bind(this)
    }
]

export function Rows(props) {
    return (
        props && props.columns && props.columns.map((element) => {
            return (
                (props.customScope && props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase()).length > 0) ?
                    props.customScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].renderTableData(element.name, props.index, props.tableData[Object.keys(props.tableData).find(objKey => objKey.toLowerCase() === element.name.toLowerCase())])
                    :
                    (props.defaultScope && props.defaultScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase()).length > 0) ?
                        props.defaultScope.filter(c => c.column.toLowerCase() === element.name.toLowerCase())[0].renderTableData(element.name, props.tableData[Object.keys(props.tableData).find(objKey => objKey.toLowerCase() === element.name.toLowerCase())])
                        :
                        <td className={(props.isCollapsedSection) ? "text-start" : "text-left"} onClick={() => (props.isCollapsedSection) ? props.setCollapse(props.index, props.isCollapsed) : null}>
                            {props.tableData[Object.keys(props.tableData).find(objKey => objKey.toLowerCase() === element.name.toLowerCase())]}
                        </td>
            )
        })
    )
}

const Table = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * props.pagination.pageSize;
        const lastPageIndex = firstPageIndex + props.pagination.pageSize;
        return props.columns.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, props.columns, props.pagination.pageSize]);

    const [basePageNo, setBasePageNo] = useState(1);

    // event function 
    const handleSorting = (columnName, value) => {
        props.pagination.pageNo = 1;
        props.pagination.orderByColumn = columnName;
        props.pagination.orderFlag = (value) ? 1 : 0;
        props.setPagination(props.pagination);
    }

    const handlePageChange = (pageNumber, isButton = false) => {
        if (props.pagination.pageNo !== pageNumber && pageNumber > 0 && pageNumber <= Math.ceil(props.totalCount / props.pagination.pageSize)) {
            if (pageNumber > props.pagination.pageNo && (pageNumber - basePageNo) > 1) {
                setBasePageNo(pageNumber + 1);
            }
            else if (pageNumber < props.pagination.pageNo && pageNumber > 1 && (basePageNo - pageNumber) === 0) {
                setBasePageNo(pageNumber - 1);
            }
            else if (isButton && pageNumber > 1) {
                setBasePageNo(pageNumber - 1);
            }
            props.pagination.pageNo = pageNumber;
            props.setPagination(props.pagination);
        }
    };

    return (
        <div>
            {(props.isLoading) ?
                <PageLoader />
                :
                <div className="">
                    <div className="table-responsive text-nowrap text-shadow-pd ">
                        <table className="table table-hover table-shadow table-striped table-borderless">
                            <thead>
                                <tr>
                                    {(props && props.columns.map((value, key) => {
                                        return (
                                            <th key={key} >
                                                <div className="font-semibold text-left">{value.title}
                                                    {(props.sortingColumns.includes(value.name)) ? (props.pagination.orderFlag === 1 && props.pagination.orderByColumn.toLowerCase() === value.name.toLowerCase()) ?
                                                        <i className="fa fa-angle-double-down p-2" onClick={() => handleSorting(value.name, !props.pagination.orderFlag)}></i>
                                                        :
                                                        <i className="fa fa-angle-double-up p-2" onClick={() => handleSorting(value.name, !props.pagination.orderFlag)}></i>
                                                        :
                                                        null}
                                                </div>
                                            </th>
                                        )
                                    }))}
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {(props && props.rows && props.rows.length > 0) ?
                                    props.rows.map((value, key) => {
                                        return (
                                            <>
                                            <tr  ><Rows key={key} columns={props.columns} tableData={value} customScope={props.customScope} defaultScope={defaultScope} index={key} setCollapse={props.setCollapse} isCollapsedSection={props.isCollapsedSection}
                                                isCollapsed={value.isCollapsed} /></tr>
                                            {(value.isCollapsed) ?
                                                props.collapsibleSection(value)
                                                : null
                                            }
                                            </>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={props.columns.length} className="text-center p-4"><i className="fas fa-empty-set pr-2"></i>No Records Found</td></tr>
                                }
                            </tbody>
                        </table>
                        <div className="card-footer">
                            <div className="mt-4">
                                <div className="text-sm text-slate-500 text-center sm:text-left mb-4">
                                    Showing <span className="font-medium text-slate-600">{((props.pagination.pageNo * props.pagination.pageSize) - props.pagination.pageSize) + 1}</span> to <span className="font-medium text-slate-600">{props.pagination.pageNo * props.pagination.pageSize}</span> of <span className="font-medium text-slate-600">{props.totalResultes}</span> results
                                </div>
                                <nav className="flex justify-between" role="navigation" aria-label="Navigation">
                                    <div className="grow text-center">
                                        <ul className="pagination justify-content-center">
                                            <li className={`page-item disabled ${(props.pagination.pageNo > 1) ? "hover:border-slate-300 text-indigo-500" : "text-slate-300 cursor-not-allowed"}`} disabled={(props.pagination.pageNo > 1) ? false : true} onClick={() => handlePageChange(props.pagination.pageNo - 1, true)}><button type="button" className="page-link page-link--with-arrow" aria-label="Previous"><svg className="page-link__arrow page-link__arrow--left" aria-hidden="true" width="8" height="13"><path d="M7.7 12.7c-.4.4-.9.4-1.3 0L0 6.5 6.4.3c.4-.4.9-.3 1.3 0 .4.4.4 1 0 1.3l-5 4.9 5 4.9c.4.4.4 1 0 1.3z"></path></svg></button></li>
                                            <li className="page-item active" >
                                                <button type="button" className={` page-link ${props.pagination.pageNo ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(props.pagination.pageNo)}>{props.pagination.pageNo}</button>
                                            </li>
                                            {Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo ?
                                                <li className="page-item">
                                                    <button type="button" className={` page-link ${props.pagination.pageNo == (props.pagination.pageNo + 1) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(props.pagination.pageNo + 1)}>{props.pagination.pageNo + 1}</button>
                                                </li>
                                                : null}
                                            {Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo + 1 ?
                                                <li className="page-item">
                                                    <button type="button" className={` page-link ${props.pagination.pageNo == (props.pagination.pageNo + 2) ? "border-slate-200 text-indigo-500 shadow-sm rounded-full" : "text-slate-600 hover:text-indigo-500 border-transparent"}`} onClick={() => handlePageChange(props.pagination.pageNo + 2)}>{props.pagination.pageNo + 2}</button>
                                                </li>
                                                : null}
                                            {Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo + 2 ?
                                                <>
                                                <li className="page-item">
                                                    <span></span>
                                                </li>
                                                </>
                                                : null}
                                            <li className={`page-item ${(Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo) ? "hover:border-slate-300 text-indigo-500" : "text-slate-300 cursor-not-allowed"}`} disabled={Math.ceil(props.totalCount / props.pagination.pageSize) > props.pagination.pageNo ? false : true} onClick={() => handlePageChange(props.pagination.pageNo + 1, true)}><button type="button" className="page-link" aria-label="Next"><svg className="page-link__arrow page-link__arrow--right" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="8" height="13"><path d="m.3 11.4 5-4.9-5-4.9C-.1 1.2-.1.7.3.3s.9-.4 1.3 0L8 6.5l-6.4 6.2c-.4.4-.9.3-1.3 0s-.4-.9 0-1.3z"></path></svg></button></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <Pagination
                                className="pagination"
                                currentPage={currentPage}
                                totalCount={props.columns.length}
                                pageSize={props.pagination.pageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Table;
