//components
import React, { useState } from 'react';
//library
import { Bars } from 'react-loader-spinner';
import InputText from "../../components/common/inputs/InputText"

const DataImportDetails = (props) => {

    return (
        <>
        <div>
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                <div className="mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Data Upload Detail</h1>
                </div>
            </div>
            <div className="accordion" id="accordionExample">
                <div className="card accordion-item active">
                    <header className="px-5 pb-0 py-4 border-b border-slate-100">   
                        <h2 className="font-semibold text-slate-800">Indefine Stock</h2>
                    </header>
                    <div
                        id="accordionOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className="card-body main-card-details-sec">
                                <div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label for="FinishDate:" className="form-label">Finish Date:</label>
                                            <InputText
                                                name="FinishDate:"
                                                className="form-control"
                                                placeholder="Finish Date"
                                                value={props.importDetails.finishDate ? props.importDetails.finishDate : ""}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label for="ImportFileName" className="form-label">Import File Name:</label>
                                            <InputText
                                                name="ImportFileName"
                                                className="form-control"
                                                placeholder="Import File Name"
                                                value={props.importDetails.importFileName ? props.importDetails.importFileName : ""}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label for="ImportId" className="form-label">Import Id:</label>
                                            <InputText
                                                name="ImportId"
                                                className="form-control"
                                                placeholder="ImportId"
                                                value={props.importDetails.importId ? props.importDetails.importId : "" }
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label for="ImportStartDate" className="form-label">Import Start Date:</label>
                                            <InputText
                                                name="ImportStartDate"
                                                className="form-control"
                                                placeholder="Import Start Date"
                                                value={props.importDetails.importStartDate ? props.importDetails.importStartDate : "" }
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">


                                            <label for="ImportStatusType" className="form-label">Import Status Type:</label>
                                            <InputText
                                                name="ImportStatusType"
                                                className="form-control"
                                                placeholder="Import Status Type"
                                                value={props.importDetails.importStatusType ? props.importDetails.importStatusType : ""}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label for="ImportType" className="form-label">Import Type: </label>
                                            <InputText
                                                name="ImportType"
                                                className="form-control"
                                                placeholder="Import Type"
                                                value={props.importDetails.importType ? props.importDetails.importType : ""}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label for="ImportTypeId" className="form-label">Import TypeId: </label>
                                            <InputText
                                                name="ImportTypeId"
                                                className="form-control"
                                                placeholder="Import TypeId"
                                                value={props.importDetails.importTypeId ? props.importDetails.importTypeId : ""}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="mb-3 col-md-6">
                                            <label for="TotalTimetoFinish" className="form-label">Total Time to Finish:</label>
                                            <InputText
                                                name="TotalTimetoFinish"
                                                className="form-control"
                                                placeholder="Total Time to Finish"
                                                value={props.importDetails.totalTimeToFinish ? props.importDetails.totalTimeToFinish : ""}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion mt-5" id="accordionExample">
                <div className="card accordion-item active">
                    <header className="px-5 pb-0 py-4 border-b border-slate-100">
                        <h2 className="font-semibold text-slate-800">Import Process Report</h2>
                    </header>
                    <div
                        id="accordionOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div className="card-body main-card-details-sec">
                                <div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label for="NoofErrorInRecords:" className="form-label">No of Error In Records:</label>
                                            <InputText
                                                name="NoofErrorInRecords:"
                                                className="form-control"
                                                placeholder="No of Error In Records"
                                                value={props.importDetails.noOfErrorInRecords ? props.importDetails.noOfErrorInRecords : ""}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label for="NoofNewRecords" className="form-label">No of New Records:</label>
                                            <InputText
                                                name="NoofNewRecords"
                                                className="form-control"
                                                placeholder="No of New Records"
                                                value={props.importDetails.noOfNewRecords ? props.importDetails.noOfNewRecords : ""}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label for="TotalRecords" className="form-label">Total Records:</label>
                                            <InputText
                                                name="TotalRecords"
                                                className="form-control"
                                                placeholder="Total Records"
                                                value={props.importDetails.totalRecords ? props.importDetails.totalRecords : ""}
                                                disabled={true}
                                            />
                                        </div>                                                                                                           
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>


    )
}

export default DataImportDetails