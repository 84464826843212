export const ButtonTypes = {
    Primary: 1,
    Secondary: 2,
    Cancel: 3
}

export const Constants = {
    RequestSections: {
        RequestInitiate: 1,
        RequestScrapping: 2,
        RequestCleaning: 3,
        RequestImporting: 4,
        RequestSummary: 5
    }
}