
export const ErrorMessage = {
	DefaultMessage : 'Something went wrong',
	NOTVALIDPATTERN : '{0} is not valid',
	FieldRequired : 'Please enter {0}',
	PasswordNotValid: 'Password must contain at least eight alphanumeric, one special character, one lower and upper case character',
	ConfirmPasswordRequired : 'Please re-enter password',
	ComparePassword:'Password does not match',
	ValueGreaterZero : 'Value should be greater than zero',
	Max : 'Value should be less than {0}',
	Min : 'Value should be greater than {0}',
	PasswordNotMatch : 'Please enter correct password',
	selectFile : 'Please select {0} file',
	EmailAddressNotValid : 'Please enter valid email address',
	SelectRequired : 'Please select {0}',
	WebUrlPatternNotMatch : 'Please enter valid url in {0} field',
	DecimalPatternNotMatch : 'Please enter valid {0}',
	NoRecordFound : 'No Records found For {0}',
	PhoneValidation : 'Please enter valid phone number',
	FaxValidation : 'Please enter valid fax',
	ConfirmMessage : 'Are you sure want {0} this {1} ?',
	ActiveMessage : 'Active {0} successfully.',
	InActiveMessage : 'Inactive {0} successfully.',
	IsEnableMessage : 'Enable {0} successfully.',
	DisableMessage : 'Disable {0} successfully',
	SessionExpired: 'Session expired, please login again',
	InvalidAuthorization: 'Invalid Authorization Credentials ',
	InvalidFileType: 'Invalid file type',
	ProspectValue: '{0} value should be greater than zero',
	MaxProspectValue: '{0} value should be smaller than {1}',
	minEndDateValue: '{0} should be greater than Start Date',
	Already_Exist: '{0} already exist',
	validateQuantty: 'Please select at least one size',
	CompareEmail:'Email address already exist',
	FileSize:'Not Valid FileSize',
	FileExist : '{0} not exist'
};

export const SuccessMessage = {
	Confirm_Question: 'Delete {0}?',
	Confirm_Delete: 'Are you sure you want to delete this {0}?',
	Delete_Success: '{0} deleted successful',
	Update_Success: '{0} updated successfully',
	Add_Success: '{0} added successfully',
	Insert_Success: '{0} inserted successfully',
	Already_Exist: '{0} already exist',
	Send_Success : '{0} send successfully',
	Confirm_Submit:'Save {0}?',
	Confirm_Save: 'Are you sure you want to save this {0}?',
	Confirm_Map: 'Are you sure you want to map?',
	Confirm_SubmitMap: 'Map?',
	Map_Alert:'{0} mapped successfully',
	Alret_Warning:'Warning !',
	Confirm_MapProduct:'Are you sure want to continue without product mapping ?',
};

