// component
import React from 'react';
// library
import { getAuthProps, signOut } from '../utils/AuthenticationLibrary';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Header = (props) => {
  const getAuth = getAuthProps()

  const location = useLocation()
  const newStr = location.pathname.replace('/', '');
  const breadCrumbs = props.componentRoutes.filter(x => x.path === newStr);


  return (
    <>
    <Helmet>
      <script src="../assets/vendor/libs/jquery/jquery.js"></script>
      <script src="../assets/vendor/libs/popper/popper.js"></script>
      <script src="../assets/vendor/js/bootstrap.js"></script>
      <script src="../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
      <script src="../assets/vendor/js/menu.js"></script>
      <script src="../assets/vendor/libs/apex-charts/apexcharts.js"></script>
      <script src="../assets/js/main.js"></script>
      <script src="../assets/js/dashboards-analytics.js"></script>
      <script async defer src="https://buttons.github.io/buttons.js"></script>
    </Helmet>
    <nav
      className="layout-navbar  container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme header-section-main"
      id="layout-navbar">
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4" href="">
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>
      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div className="navbar-nav align-middle">
          <div className="nav-item align-middle">
            {breadCrumbs.map((bread) => (
              <><h3 className="logdetails my-5">{bread.title}</h3></>
            ))}
          </div>
        </div>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li className="nav-item navbar-dropdown dropdown-user dropdown">

            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="/UserProfile">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3 mt-1">
                      <img src="../userProfile.png" alt="" className="w-px-40 h-auto rounded-circle" />
                    </div>
                    <div className="flex-grow-1">

                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">Profile</span>
                </a>
              </li>

              <li>
                <a href="#" className="dropdown-item" >
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </a>
              </li>

            </ul>
          </li>
        </ul>
      </div>
    </nav>
    </>
  )
}

export default Header;
