//components
import React, { Component } from 'react';
//library
import { Navigate } from 'react-router-dom';
import { ButtonTypes } from '../../utils/Constants';
import { ErrorMessage } from '../../utils/Messages';
import { getAuthProps } from '../../utils/AuthenticationLibrary';
import ValidationText from '../../utils/validation/ValidationText';
import { isValidForm, validate } from '../../utils/validation/CommonValidator';
import InputText from '../../components/common/inputs/InputText'
//Services
import SwalServices from '../../services/swalServices/SwalServices';
import ImportDataServices from '../../services/axiosServices/apiServices/ImportDataServices';

export class AddMainProduct extends Component {
  // Constructor  and Component method
  constructor(props) {
    super(props)
    this.swalServices = new SwalServices();

    this.getImportDataService = new ImportDataServices();
    this.authUser = getAuthProps()
    this.inputRef = React.createRef();

    this.state = {
      searchingText: "",
      setSidebarOpen: false,
      setSelectedItems: [],
      setBasicModalOpen: false,
      isBtnLoading: false,

      importFile: {
        importTypeId: 4,
        importTitle: "",
        importFileName: "",
        createdBy: 1,
        isFileServer: false,
        requestedBy: 1,
        supplierID: ''
      },

      redirect: null,
      validationRule: {

        importTitle: [
          {
            type: "require", message: ErrorMessage.FieldRequired.replace("{0}", "Upload Title Name")
          },
        ],
        importFileName: [
          {
            type: "require", message: ErrorMessage.FieldRequired.replace("{0}", "File Name xlsx")
          },
          {
            type: "fileName", message: ErrorMessage.FieldRequired.replace("{0}", "File Name xlsx")
          }
        ]
      },
      validState: {
        isValid: true,
        error: {},
      }
    }
  }
  componentDidMount() {
  }
  // event function 

  handleSelectedItems = (selectedItems) => {
    this.setState({ setSelectedItems: selectedItems })
  };

  validateField = (key) => {
    const newValidState = validate(key, this.state.importFile, this.state.validationRule, this.state.validState);
    this.setState({ validState: newValidState });
  }


  handleChangeEvent = (value) => {
    this.setState({ isLoading: true });
    this.setState({ searchingText: value })
    this.handleSearching(value)
    if (value === '') {
      this.setState({ isLoading: false });
    }
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.importFile;
    detail[name] = value;
    this.setState({ importFile: { ...detail } });
  }


  handleChangeCheckBox = (e) => {
    let { name, checked } = e.target;
    let detail = this.state.importFile;
    detail[name] = checked;
    this.setState({ importFile: detail })
  }



  setFile = (e) => {
    const file = e.target.value;
    const fileExt = file.split('.');
    let Importfile = { ...this.state.importFile };
    Importfile.importFileName = file;
    Importfile["file"] = file;
    this.setState({ importFile: { ...Importfile } }
    );

  }

  // API function 

  addImport = () => {

    if (this.isValidateAllFields()) {
      let request = { ...this.state.importFile }
      this.setState({ isLoading: true });
      this.setState({ isBtnLoading: true });
      this.getImportDataService.admin_AddImport(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorType === "EXISTS") {
            this.swalServices.Toaster(ErrorMessage.Already_Exist.replace("{0}", "Excel Sheet"));
          }
          else if (response.responseItem.responseContent.errorType === "File not exist in folder") {
            this.swalServices.Toaster(ErrorMessage.FileExist.replace("{0}", "File"));
          }
          else if (response.responseItem.responseContent.errorType === "Invalid Column") {
            this.swalServices.Toaster("The column are invalid")
          }
          else {
            this.swalServices.Success();
            this.onViewRequestDetail()
            this.onReset();
          }
        }
        this.setState({ isBtnLoading: false })
        this.setState({ isLoading: false });
      })
    }
  }


  // other function 
  onViewRequestDetail = () => {
    this.setState({ redirect: "/ProductUploadHistory" });
  }
  onReset = () => {
    if (this.inputRef)
      this.inputRef.current.value = null;
    let reset = {
      ...this.state.importFile,
      importTitle: "",
      importFileName: "",
      isFileServer: false
    }
    this.setState({ importFile: reset })
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(this.state.importFile, this.state.validationRule, this.state.validState);
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
      <div className="mb-4">
        <div className="accordion" id="accordionExample">
          <div className="card accordion-item active ">

            <div
              id="accordionOne"
              className="accordion-collapse collapse show "
              data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <div className="card-body">
                  <div>
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label for="Title" className="form-label">Request Name</label>
                        <InputText
                          name="importTitle"
                          className="form-control"
                          value={this.state.importFile.importTitle}
                          onChange={(event) => this.handleChange(event)} placeholder=''
                          onBlur={() => this.validateField("importTitle")} />
                        <ValidationText error={this.state.validState.error.importTitle} />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label for="importFileName" className="form-label">File Name</label>
                        <input className="form-control" id="importFileName" type="text" name="importFileName" ref={this.inputRef}
                          defaultValue={this.state.importFileName} onChange={(e) => this.setFile(e)}
                          onBlur={() => this.validateField("importFileName")} />
                        <ValidationText error={this.state.validState.error.importFileName}
                        />
                      </div>

                      <div className="mb-3 col-md-2">
                        <div className='mt-1 ml-4'>
                          <input className="form-check-input" type="checkbox" name="isFileServer"
                            checked={this.state.importFile.isFileServer} onChange={(event) => this.handleChangeCheckBox(event)} id="isFileServer" />
                          <label className="form-check-label inputColor ml-2" for="isFileServer"> isFileServer</label>
                        </div>
                      </div>
                      <div className=" col-md-12 d-flex justify-content-end">

                        <button className="tab-title tab-title--active ml-2 btn-hover color-3" onClick={() => this.onReset()}>Cancel</button>
                        <button type="button" className="tab-title tab-title--active ml-2 btn-hover color-2" onClick={() => this.addImport()} isLoading={this.state.isBtnLoading}> Add </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      </>

    )
  }
}

export default AddMainProduct