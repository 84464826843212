//components
import React from 'react';
//library
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from '../pages/authentication/Login';
import Layout from '../shared/Layout';
import ResetPassword from '../pages/authentication/ResetPassword';

const LayoutNavigation = (props) => {
    return (
        
        <Router>
            <React.Suspense fallback={null}>
                <Routes>
                    <Route name="Layout" path="/" element={<Layout componentRoutes={props.componentRoutes} />}>
                        {props.componentRoutes && props.componentRoutes.map((route, idx) => {
                            return route.component && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={<route.component authDetails={route.authDetails} />} />
                            )
                        })}
                    </Route>
                     <Route exact name="Login" path="/login" element={<Login authDetails={props.authDetails} />} />
                </Routes>
            </React.Suspense>
        </Router>
    )
}

export default LayoutNavigation;