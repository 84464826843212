// Library
import { axiosPost, axiosGet } from '../AxiosRequests';
import { GetAllSupplierName } from '../ApiEndPoints';

export default class GetAllSupplierTypeName{
        // API function 
    async getAllSupplierName(request) {
        return axiosGet(GetAllSupplierName, request);
    }
 
}