//components
import React, { Component } from 'react';
//library
import { Navigate } from 'react-router-dom';
import Button from '../common/Button';
import { ButtonTypes } from '../../utils/Constants';
import DataImportDetails from './DataImportDetails'
import { ErrorMessage } from '../../utils/Messages';
import { DataHistory } from '../DataHistory/DataHistory';
//Services
import ImportDataServices from '../../services/axiosServices/apiServices/ImportDataServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class DataImport extends Component {
    // Constructor  and Component method
  constructor(props) {
    super(props)
    this.ImportDataServices = new ImportDataServices();
    this.swalServices = new SwalServices();
    this.state = {
      setSidebarOpen: false,
      setSelectedItems: [],
      setBasicModalOpen: false,
      indofineStock: {},
      importProcessReport: {},
      importDetails: {},
      isLoading: false,
      redirect: null,
    }
  }

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let ImportId = params.get("")
    this.getImportDetailsById(ImportId);
  }

  // event function 

  handleSelectedItems = (selectedItems) => {
    this.setState({ setSelectedItems: selectedItems })
  };

  onBesicModel = () => {
    this.setState({ setBasicModalOpen: true })
  }

  onBesicModelClose = () => {
    this.setState({ setBasicModalOpen: false })
  }
  back = () => {
    this.setState({ redirect: "/ProductUploadHistory" });
  }

// API function 
  getImportDetailsById = (ImportId) => {
    this.setState({ isLoading: true });
    this.ImportDataServices.admin_GetImportDetailsById(ImportId).then((response) => {
     
       if (response.statusCode === 200 && response.responseItem.responseContent.length > 0 ) {
        this.setState({
          importDetails: response.responseItem.responseContent[0]
        })
      }
      else {
        this.swalServices.Toaster(ErrorMessage.DefaultMessage);
      }
      this.setState({ isLoading: false });
      
    })
  }



  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <Button type={ButtonTypes.Cancel} title={<i className="fas fa-arrow-left"></i>} buttonClick={() => this.back()} />
              <DataHistory
                indofineStock={this.state.indofineStock}
                importProcessReport={this.state.importProcessReport}
                importDetails={this.state.importDetails}
                isLoading={this.state.isLoading}
              />
            </div>
          </main>
        </div>
      </div>
    )
  }
}