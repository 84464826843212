import { IsProdMode } from '../../utils/AppSetting';

// Web API url
//export const APIURL = (IsProdMode) ? "http://testingimsapi.echemportal.net/api/" : "https://localhost:44321/api/";
//export const APIURL = (IsProdMode) ? "https://preprodapi.aurumpharmatech.com/api/" : "https://localhost:44318/api/";
//export const APIURL = (IsProdMode) ? "http://dataimporttestapi.echemportal.net/" : "https://localhost:44318/api/";//Testing
export const APIURL = (IsProdMode) ? "https://dataimportapi.echemportal.net/" : "https://localhost:44318/api/";//Live



//Import Data Services
export const AddImport = "Import/AddImport";
export const GetImportDetailsById = "Import/GetImportDetailsById?id={0}"
export const GetImportList = "Import/GetImportList"
export const GetImportProcessUpdate = "Import/GetImportProcessUpdate?id={0}"

export const GetAllSupplierName = "Import/GetAllSupplierName";

//Common 
export const RenderImageUsingImageType = "Common/RenderImageUsingImageType?type={0}&image={1}";

// export const DownloadInputFile = "https://localhost:44368/api/File/GetFile"; // local 
export const DownloadInputFile = "http://47.190.78.203:81/api/File/GetFile"; // Server 