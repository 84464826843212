// component
import React, { } from 'react';
// library
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Sidebar = () => {

  const location = useLocation();

  return (
    <>
    <Helmet>
      <script src="../assets/vendor/libs/jquery/jquery.js"></script>
      <script src="../assets/vendor/libs/popper/popper.js"></script>
      <script src="../assets/vendor/js/bootstrap.js"></script>
      <script src="../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
      <script src="../assets/vendor/js/menu.js"></script>
      <script src="../assets/vendor/libs/apex-charts/apexcharts.js"></script>
      <script src="../assets/js/main.js"></script>
      <script src="../assets/js/dashboards-analytics.js"></script>
      <script async defer src="https://buttons.github.io/buttons.js"></script>
    </Helmet>
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo">
        <a href="/Dashboard" className="app-brand-link">
        
          <span className="app-brand-text  menu-text fw-bolder Name1"> Data Update Tool </span>
        </a>
        <a href="" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
       
        <li className={`menu-item ${location.pathname === "/Dashboard" ? "active" : null}`} >
          <a href="/Dashboard" className="menu-link">
            <i className="menu-icon far fa-folder"></i>
            <div data-i18n="Dashboard">Dashboard</div>
          </a>
        </li>

        <li className={`menu-item${location.pathname === "/AddNewProductRequest" ? "active" : null} || ${location.pathname === "/ProductUploadList" ? "active" : null}`}>


          <li className={`menu-item ${location.pathname === "/AddNewProductRequest" ? "active" : null}`}>
            <a href="" className="menu-link menu-toggle">
              <i class="menu-icon fa fa-database" aria-hidden="true"></i>
              <div data-i18n="Add New Upload Request">Main Data Update</div>
            </a>

            <ul className="menu-sub">
              <li className={` ${location.pathname === "/AddNewProductRequest" ? "active" : null}`}>
                <a href="/AddMainProduct" className="menu-link">
                  <div data-i18n="Add Product Request">Add Main Product</div>
                </a>
              </li>
              <li className={` ${location.pathname === "/ProductUploadHistory" ? "active" : null}`} >
                <a href="/ProductUploadHistory" className="menu-link">
                  <div data-i18n="Product List Request">Product Update History</div>
                </a>
              </li>
            </ul>
          </li>
          <li className={`menu-item ${location.pathname === "/SupplierDatabase" ? "active" : null}`}>
            <a href="" className="menu-link menu-toggle">
              <i class="menu-icon fa fa-street-view" aria-hidden="true"></i>
              <div data-i18n="Add New Upload Request">Supplier Data Update</div>
            </a>
            <ul className="menu-sub">
              <li className={` ${location.pathname === "/AddDataUpdateRequest" ? "active" : null}`}>
                <a href="/AddDataUpdateRequest" className="menu-link">
                  <div data-i18n="Add New Upload Request">Add Data Update Request</div>
                </a>
              </li>
              <li className={`menu-item ${location.pathname === "/SupplierList" ? "active" : null}`}>
              

                <li className={` ${location.pathname === "/ProductUpdateHistory" ? "active" : null}`}>
                  <a href="/ProductUpdateHistory" className="menu-link">
                    <div data-i18n="Product List">Product Update History</div>
                  </a>
                </li>
                <li className={` ${location.pathname === "/stockUpdateHistory" ? "active" : null}`}>
                  <a href="/StockUpdateHistory" className="menu-link">
                    <div data-i18n="Stock List">Stock Update History</div>
                  </a>
                </li>
                <li className={` ${location.pathname === "/PriceUpdateHistory" ? "active" : null}`} >
                  <a href="/PriceUpdateHistory" className="menu-link">
                    <div data-i18n="Price List">Price Update History</div>
                  </a>
                </li>

              </li>
            </ul>
          </li>
        </li>

      </ul>
      <footer className="footer-section mt-5">
        <div className="copyright-area ">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-6 text-center ">
                <div className="copyright-text">
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </aside>
    </>
  )
}

export default Sidebar
