// Library
import { axiosGet, axiosPostAuthorize, axiosPost } from '../AxiosRequests';
import { AddImport, GetImportDetailsById, GetImportList } from '../ApiEndPoints';

export default class ImportDataServices {

    async admin_AddImport(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("importTypeId", request.importTypeId);
        formData.append("importTitle", request.importTitle);
        formData.append("importFileName", request.importFileName);
        formData.append("createdBy", request.createdBy);
        formData.append("isFileServer", request.isFileServer);
        formData.append("requestedBy", request.requestedBy);
        formData.append("supplierID", request.supplierID);

        return axiosPostAuthorize(AddImport, formData, true)
    }
    // API function 

    async admin_GetImportDetailsById(request) {
        return axiosGet(GetImportDetailsById, request);
    }

    async admin_GetImportList(request) {
        return axiosPost(GetImportList, request);
    }
}