//components
import React, { Component } from 'react';
//library
import { Navigate } from 'react-router-dom';
import { ErrorMessage } from '../../utils/Messages';
import Table from '../../components/tables/Table';
import moment from 'moment';

//Services
import SwalServices from "../../services/swalServices/SwalServices";
import ImportDataServices from '../../services/axiosServices/apiServices/ImportDataServices';

export default class StockUpdateHistory extends Component {
          // Constructor  and Component method
    constructor(props) {
        super(props)
        this.swalServices = new SwalServices();
        this.ImportDataServices = new ImportDataServices();
        this.state = {
          getImport: [],
          searchingText: "",
          setSidebarOpen: false,
          setSelectedItems: [],
          setBasicModalOpen: false,
          totalResultes: "",
          pagination: {
            pageNo: 1,
            pageSize: 25,
            orderByColumn: "CreatedDate",
            orderFlag: 1,
            searchText: "2",
          },
          importFile: {
            importTypeId: "",
            importTitle: "",
            importFileName: "",
            createdBy:1
          },
          redirect: null,
          isBtnLoading: false,
        }
        this.handleChange = this.handleChange.bind(this);
      }
  
    
      componentDidMount() {
        this.getImportList();
      }
      // event function 
    
      handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.importFile;
        detail[name] = value;
        this.setState({ importFile: { ...detail } });
      }
    
      handleSelectedItems = (selectedItems) => {
        this.setState({ setSelectedItems: selectedItems })
      };

      handleChangeEvent = (value) => {
        this.setState({ isLoading: true });
        this.setState({ searchingText: value })
        this.handleSearching(value)
        if (value === '') {
          this.setState({ isLoading: false });
          this.getImportList();
        }
      }
    
      actions = (element, index, value) => {
        return (element !== "importId") ? null
          : <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
            <i className="fas fa-eye  cursor-pointer ... d-flex justify-content-center" onClick={() => this.onViewImportDetails(value)}></i>
          </td>
      }
    
      onViewImportDetails = (value) => {
        this.setState({ redirect: '/DataStockList/ImportId?=' + value });
      }
    
      setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getImportList();
      }
    
      handleSearching = (searchQuery) => {
        if (searchQuery.trim() !== "") {
          this.GetSearch(searchQuery);
        }
        else if (searchQuery.trim() === "") {
          this.getImportList(this.state.pagination);
        }
      }
    
      handleTypeSelection = (e) => {
        let newImport = { ...this.state.importFile };
        newImport.importTypeId = e.target.value;
        this.setState({ importFile: newImport });
      }
    
      setFile = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          let Importfile = { ...this.state.importFile };
          Importfile.importFileName = file.name;
          Importfile["file"] = file;
          this.setState({ importFile: { ...Importfile } });
        }
        else {
          this.swalServices.Toaster(ErrorMessage.InvalidFileType);
        }
      }

      // API function 
      getImportList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.ImportDataServices.admin_GetImportList(pagination).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({ getImport: response.responseItem.responseContent.itemList });
            this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            const itemList = response.responseItem.responseContent.itemList
            itemList.forEach(function (i) {
              i.importTitle = i.importTitle ? i.importTitle : "-"
              i.importFileName = i.importFileName ? i.importFileName : "-"
              i.ImportTypeName = i.importType ? i.importType : "-"
              i.supplierName = i.supplierName ? i.supplierName : "-";
              i.status = i.importStatusType ? i.importStatusType : "-";
              i.startDate = moment(i.importStartDate).format('DD-MM-YYYY hh:mm:ss') ? moment(i.importStartDate).format('DD-MM-YYYY hh:mm:ss') : "-";
              if(i.requestedBy == 1)
              {
                i.requestedBy = 'web' 
              }
              else if(i.requestedBy == 2)
              {
                i.requestedBy =  "client"
              }
            })
          }
          else {
            this.swalServices.Toaster(response.message);
          }
          this.setState({ isLoading: false });
        })
      }
      GetSearch = (searching) => {
        let request = {
          pageNo: 1,
          pageSize: 25,
          orderByColumn: "CreatedDate",
          orderFlag: 1,
          searchText: searching
        }
        this.setState({ isLoading: true });
        this.getImportDataService.admin_AddImport(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({ getImportData: response.responseItem.responseContent.itemList });
            this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
          }
          else {
            this.swalServices.Toaster(response.message);
          }
          this.setState({ isLoading: false });
        })
      }

          
  // other function 
      handlePageChange = (pageNumber) => {
        if (this.state.pagination.pageNo !== pageNumber) {
          this.state.pagination.pageNo = pageNumber;
          this.getImportList(this.state.pagination);
        }
      };
    
    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
          }
          return (
          
                <main>
                
                      <Table
                       columns={[{ name: 'importTitle', title: 'Title' }, { name: 'ImportFileName', title: 'FileName' }, { name: 'SupplierName', title: 'Supplier Name' },{ name: 'Status', title: 'Status' },{ name: 'StartDate', title: 'StartDate' },{name:'requestedBy',title: 'requested By'} ,{ name: 'importId', title: 'Actions ' }]}
                        rows={this.state.getImport}
                        sortingColumns={["importTitle", "ImportFileName","SupplierName","StartDate","Status","requestedBy"]}
                        pagination={this.state.pagination}
                        totalResultes={this.state.totalResultes}
                        totalCount={this.state.totalResultes}
                        setPagination={this.setPagination.bind(this)}
                        customScope={[
                          {
                            column: 'importId',
                            renderTableData: this.actions.bind(this)
                          },
                        ]}
                      />
                  
                </main>
         
          )
        }
      }
