//components
import ProductUploadHistory from '../pages/AddNewProductRequest/ProductUploadHistory';
import AddMainProduct from '../pages/AddNewProductRequest/AddMainProduct';
import AddDataUpdateRequest from '../pages/SupplierDatabase/AddDataUpdateRequest'
import DataImportInfo from '../components/DataImport/DataImportInfo';
import DataProductList from '../components/DataImport/DataProductList';
import DataStockList from '../components/DataImport/DataStockList';
import DataPriceList from '../components/DataImport/DataPriceList';
import ProductUpdateHistory from '../pages/SupplierList/ProductUpdateHistory';
import StockUpdateHistory from '../pages/SupplierList/StockUpdateHistory';
import PriceUpdateHistory from '../pages/SupplierList/PriceUpdateHistory';
import Dashboard from '../pages/dashboard/Dashboard';
import { DataHistory } from '../components/DataHistory/DataHistory';
import DataHistoryPrice from '../components/DataImport/DataHistoryPrice';

const ComponentNavigation = [
    { path: 'Dashboard', title: 'Dashboard', component: Dashboard, hasParams: false },
    { path: 'DataHistory/ImportId', title: 'DataHistory', component: DataHistory, hasParams: false },
    { path: "AddMainProduct", title: 'Add Main Product ', component: AddMainProduct, hasParams: false },
    { path: "AddDataUpdateRequest", title: 'Add Data Update Request ', component: AddDataUpdateRequest, hasParams: false },
    { path: "DataImportInfo/ImportId", title: 'DataImportDetails', component: DataImportInfo, hasParams: true },
    { path: "DataProductList/ImportId", title: 'DataProductList', component: DataProductList, hasParams: true },
    { path: "DataHistoryPrice/ImportId", title: 'DataHistoryPrice', component: DataHistoryPrice, hasParams: true },
    { path: "DataPriceList/ImportId", title: 'DataPriceList', component: DataPriceList, hasParams: true },
    { path: "DataStockList/ImportId", title: 'DataStockList', component: DataStockList, hasParams: true },
    { path: "ProductUploadHistory", title: 'Product Update History', component: ProductUploadHistory, hasParams: false },
    { path: "ProductUpdateHistory", title: 'Product Update List', component: ProductUpdateHistory, hasParams: false },
    { path: "StockUpdateHistory", title: 'Stock Update List', component: StockUpdateHistory, hasParams: false },
    { path: "PriceUpdateHistory", title: 'Price Update List', component: PriceUpdateHistory, hasParams: false },
];

export default ComponentNavigation;
