//components
import React from 'react';

function InputText(props) {
  return (
    <div>
      <input type="text" className="form-control" 
        id={props.name} 
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        maxLength={props.maxLength}
      />
    </div>
  )
}

export default InputText